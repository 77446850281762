.slide-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 90vh;
  position: absolute;
  margin: 0 auto;
  top: 7.5rem;
  right: -1rem;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  z-index: 1000;
  padding-left: 15px;
  margin-left: -15px;
  &--inner-container {
    // display: flex;
    // flex-direction: column;
  }

  & .button-close {
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    margin: 5px;
  }

  &.hide {
    transform: translate3d(+85vw, 0, 0);
    display: flex;
    flex-direction: column;

    & .content-container {
      display: flex;
      flex-direction: column;
      margin-left: 8rem;
    }
  }

  & .tab-button-div {
    display: flex;
    margin-top: 5px;
    align-items: center;
    background-color: white;
    height: 100px;
  }
  & .tab {
    background-color: gray;
    transform: rotate(-90deg);
    width: 100px;
    height: 20px;
  }

  &.partial {
    transform: translate3d(+50vw, 0, 0);
    background-color: white;
    position: absolute;
    top: 7.9rem;
    display: flex;
    height: 90vh;

    & .content-container {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      background-color: white;
      overflow-y: scroll;
      overflow-x: auto;
      height: 89vh;
    }

    & .slide-menu-container {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      // background-color: green;
    }

    & .slide-activity-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 26vw;
      margin-top: 2rem;

      & .display-columns {
        display: flex;
      }

      & .cards-row-slider {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      & .charts-row-slider {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        & .slider-chart {
          display: flex;
          width: 33%;
          height: 100%;
        }

        & .dist-banner {
          background-color: white;
          box-sizing: border-box;
          padding: 1.5rem;
          height: 25%;
          width: 80%;
          border-radius: 10px;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;

          & p {
            font-size: 2rem;
            font-weight: 600;
            text-align: left;
          }

          & .p-center {
            padding-top: 1rem;
            text-align: center;
            font-weight: 800;
          }

          // & .peer-button-row {
          //   display: flex;
          // }
        }
      }
    }

    & .slide-activity-label {
      margin: 1rem;
    }

    & .slide-menu-item {
      padding: 0.5rem;
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.show {
    transform: translate3d(2vw, 0, 0);
    background-color: white;
    // background-color: white;
    position: absolute;
    top: 7.9rem;
    margin: 0 1rem;
    width: 81vw;
    height: 90vh;

    & .content-container {
      background-color: white;
      padding: 1rem 3rem;
    }
  }

  & .slide-menu {
    background-color: white;
    font-size: 2rem;
    padding: 1rem 0;
    margin-left: 4.5rem;
    // border-top: 1px solid black;
  }
}
.button-row {
  display: flex;
  flex-direction: column;
  float: left;
  width: 1vh;
  height: max-content;

  & .button-row-arrow {
    margin-bottom: 3rem;
  }

  & .menu-item {
    margin-bottom: 5.5rem;
    margin-top: 0.5rem;
    //padding: 3rem 0rem;
    // color: "#A2FFA1";
    font-size: 1.5rem;
    //text-decoration: underline;
    font-family: "Manrope", sans-serif;
    color: white;
    // text-decoration: bold;
    cursor: pointer;
    //background-color: rgb(148, 148, 184);
    transform: translate(-29px, 10px) rotate(-90deg);
    width: 80px;
    height: 20px;
    border: none;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #090825;

    &-arrow {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      //padding: 3rem 0;
      transform: translate(-5px, 10px) rotate(-90deg);
      width: 30px;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
      border-bottom-left-radius: 75px;
      border-bottom-right-radius: 75px;
    }

    &.inactive {
      background-color: #74739d;
      color: rgb(164, 164, 173);
    }
  }
}

.buttons-row {
  display: inline-flex;
  flex-direction: row;
  // background-color: #74739d;
  // transform: translate(60px, -350px);
  // float:top;
  // top:500px;
  // float: right;
  //     flex: 1;
  // flexDirection: 'row';
  // justifyContent: 'space-between;'
  // display:inline-flex;
  // flex-direction: row;

  // padding: 5rem;
  // padding-bottom: 5rem;

  // bottom: 5rem;

  //  transform: translate(-140PX,360px);
}
