.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  margin: 3rem auto;

  & .login-label {
    font-size: 3rem;
  }

  & .login-input {
    width: 40%;
    height: 4rem;
    margin: 2rem;
  }

  & .login-button {
    width: 30%;
    height: 4rem;
  }
}
