.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #d4d3df;

  & .logo-container {
    display: flex;
    text-decoration: none;
    margin-left: 15px;

    & .logo {
      display: flex;
      font-size: 3.5rem;
      color: #d4d3df;
      padding-top: 5px;
      text-decoration: none;

      & sup {
        font-size: 1.2rem;
      }
    }
  }

  & .title {
    font-size: 2.2rem;
    letter-spacing: 0.05rem;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  & .options {
    margin-top: 1.5rem;
    padding-top: 1rem;
    margin-right: 70px;
    display: flex;

    & .option {
      margin-top: 1rem;
      font-size: 1.5rem;
      text-decoration: none;
      color: #d4d3df;
      margin-right: 2rem;
    }
  }

  & .logo-image {
    width: 100px;
  }
}
//   & .options {
//     width: 200px;
//     text-align: center;
//     padding-top: 25px;
//   }

//   & .option {
//     color: #d4d3df;
//     text-decoration: none;
//     font-size: .8rem;
//   }
// }
