ul {
  list-style: none;
  // padding: 5px;

  & li {
    padding: 2px;
  }

  & .nav-link {
    display: flex;
    color: #d4d3df;
    text-decoration: none;
    font-size: 1.8rem;
  }

  & .nav-row {
    display: flex;
  }

  & .nav-arrow {
    display: flex;
    font-size: 2.2rem;
  }

  & .sub-nav {
    display: flex;
    padding-left: 1.2rem;
  }

  & .bridge-sub {
    display: flex;
    padding-left: 2rem;
  }

  & .sync-sub {
    display: flex;
    padding-left: 3.2rem;
  }

  & .peer-sub {
    display: flex;
    padding-left: 4.2rem;
  }

  & .selected {
    color: red;
    background-color: #090825;
  }

  // & .menu {
  //   display: flex;
  //   flex-direction: column;
  // }
}
