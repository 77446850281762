
.peer-button-container{
  // width: 100%;
  // height:25%;
  // background-color: green;
  // min-width:50%;
  // max-height: 25%;
  transform: translate(80PX,10px);
  // display: inline-block;
  // vertical-align: top;
  
}
.peer-button {
  // display:flex;
  // display: inline-block;
  // justify-content: flex-start;
  // vertical-align: top;
  // flex-direction: row;
  
  // vertical-align:top;

  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  // text-align: center;
  // margin-top: 1rem;
    //  transform: translate(-150PX,360px);
   }