.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #d4d3df;

  & .footer-container {
    align-content: center;
    font-size: 1.4rem;
  }
}
