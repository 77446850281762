.content-view {
  display: flex;
  flex-direction: column;
  width: 97%;
  align-items: center;
  justify-content: center;
  max-height: 90vh;
}
.menu-item {
  margin-bottom: 6rem;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  transform: translate(-30px) rotate(90deg);
  width: 8rem;
  height: 2rem;
  border-top-left-radius: 15rem;
  border-top-right-radius: 15rem;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  background-image: radial-gradient(rgb(38, 22, 128) 5%, rgb(9, 8, 36) 60%);
}
.menu-item-arrow {
  margin-bottom: 2rem;

  //padding: 3rem 0rem;
  // color: "#A2FFA1";
  font-size: 1.5rem;
  //text-decoration: underline;
  color: white;
  // text-decoration: bold;
  cursor: pointer;
  //background-color: rgb(148, 148, 184);
  transform: translate(-30px, 10px) rotate(-90deg);
  width: 4rem;
  height: 2rem;
  border-top-left-radius: 15rem;
  border-top-right-radius: 15rem;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;

  background-image: radial-gradient(rgb(38, 22, 128) 5%, rgb(9, 8, 36) 60%);
}

.text-row {
  display: flex;
  max-height: 60vh;
  width: 95%;
  margin-left: 2rem;
  justify-content: space-around;
}

.card-45 {
  word-wrap: break-word;
}

.text-left {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  display: flex;
  max-height: 90%;
  width: 40%;
  text-align: center;

  & h2 {
    margin-bottom: 1rem;
  }

  & p {
    font-size: 1.2rem;
    word-wrap: break-word;
  }
  .text-area {
    border: 2px solid black;
    padding: 10px;
    width: 100%;
  }
}

.text-middle {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-height: 90%;
  width: 15%;

  & .component-button {
    height: 7rem;
    width: 100%;
    font-size: 2rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

    &span {
      justify-content: center;
    }

    &.search {
      width: 45%;
      margin-left: 5px;
    }
  }
}

.text-right {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  display: flex;
  max-height: 90%;
  width: 40%;
  text-align: center;

  & h2 {
    margin-bottom: 1rem;
  }
}

.text-area {
  border: 2px solid black;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  height: 90%;

  & p {
    word-wrap: break-word;
    font-size: 1.2rem;
  }
}

// .card-row {
//   display: none;
// }
// .card {
//   display:none;
// }
// .sync-banner {
//   display:none;
// }
// .buttons-row {
//   display:none;
// }
// .charts-row{
//   display:none;
// }
// .dist-banner {
//   display:none;
// }
