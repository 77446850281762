@import "../../sassStyles/utilities";

.ledger-view {
  display: flex;
  flex-direction: column;
  width: 98%;
}

.title {
  width: 100%;
  font-size: 3.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  & sup {
    font-size: 1.2rem;
  }
}

.ledger-button {
  margin: 5px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background-color: #fff;
  cursor: pointer;

  &.selected {
    background-color: rgba(0, 0, 255, 0.7);
    color: #fff;
  }
}

.ledger-chart-row {
  display: flex;
  width: 95%;
  max-height: 50vh;
  margin-top: 2rem;
  // background-color: red;

  & .ledger-chart-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: blue;

    & .chart-left-top {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 90%;
      height: 20%;
      margin: 2rem;

      & .account-balance {
        width: 30rem;
        display: flex;
        flex-direction: column;
        height: 80%;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        background-color: #102033;
        color: #fff;
        border: 1px solid darkgray;
        letter-spacing: 2px;
        background-color: yellow;

        & h3 {
          font-size: 2rem;
        }

        & h2 {
          font-size: 2.5rem;
        }
      }
    }
  }

  & .ledger-chart-right {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 1rem;
    justify-content: center;
    align-items: center;

    & table {
      font-size: 1.5rem;
      border: 1px solid black;
      display: block;
      height: 15rem;
      overflow-y: auto;
      margin-top: 3rem;

      & th {
        width: 10rem;
        position: sticky;
      }

      & td {
        text-align: center;

        &.time-stamp {
          font-size: 1.2rem;
          width: 50%;
        }
      }
    }
    & .content-id {
      font-weight: bold;

      &.content-click {
        cursor: pointer;
      }
    }
  }

  .primary-consensus {
    // transform: scale(1.5);
    width: 60vh;
    height: 30vh;
  }
}
