@import "./sassStyles/variables";
@import "./sassStyles/utilities";

.app-container {
  display: grid;
  width: 100vw;
  min-height: 100vh;

  grid-template-rows: 10vh 90vh 10vh;
  grid-template-columns: repeat(5, 1fr);
}

.grid-container {
  background-color: $lightGreyBackgroundColor;
  padding: 0.5rem 2rem 2rem 2rem;

  & .slider-button-container {
    position: absolute;
    left: 97vw;

    &.hidden {
      display: none;
    }
  }

  &.header {
    background-color: $primaryColor;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  &.side-container {
    background-color: $primaryColor;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    overflow-y: scroll;
  }

  &.main-content {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
  }

  &.footer {
    background-color: $primaryColor;
    grid-row: 3 / -1;
    grid-column: 1 / -1;
  }
}
