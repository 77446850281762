.content-view {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .contract {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    text-align: center;
  }

  .input {
    width: 90%;

    &.input-area {
      height: 200px;
    }
  }

  .label {
    display: block;
    margin: 10px 0;
    font-size: 15px;
  }

  .button {
    margin-top: 15px;
    padding: 15px;
    font-size: 20px;
    border-radius: 5px;
  }
}

// .text-row {
//   display: flex;
//   height: 400px;
//   width: 100%;
//   justify-content: space-around;
// }

// .text-left {
//   margin-top: 12px;
//   display: flex;
//   height: 95%;
//   width: 40%;
//   text-align: center;

//   & h2 {
//     margin-bottom: 3rem;
//   }

//   & p {
//     font-size: 1.2rem;
//   }
// }

// .text-middle {
//   margin-top: 12px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   height: 95%;
//   width: 15%;

//   & .component-button {
//     height: 7rem;
//     width: 100%;
//     font-size: 2rem;
//     border-radius: 10px;
//     background-color: #fff;
//     box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

//     &span {
//       justify-content: center;
//     }

//     &.search {
//       width: 45%;
//       margin-left: 5px;
//     }
//   }
// }

// .text-right {
//   margin-top: 12px;
//   display: flex;
//   height: 95%;
//   width: 40%;
//   text-align: center;

//   & h2 {
//     margin-bottom: 3rem;
//   }
// }

// .text-area {
//   border: 2px solid black;
//   padding: 10px;
//   width: 100%;
// }
