@import "../../sassStyles/variables";

.button-group {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  margin-right: 5rem;
  z-index: 10;

  & button {
    cursor: pointer;
    background-color: $primaryColor;
    color: $lightGreyBackgroundColor;
    height: 4rem;
    width: 8rem;
    box-shadow: 3px 2px 6px $DarkGreyColor;
    border: none;
    transition: 0.2s;

    &:active {
      transform: scale(0.9);
    }
  }

  & .drop-down-menu {
    background-color: $lightGreyBackgroundColor;
    width: 12rem;
    padding: 1rem;
    position: absolute;
    top: 4.5rem;
    border-radius: 0.5rem;

    & .drop-down-item {
      color: $primaryColor;
      font-size: 1.6rem;
    }
  }

  & input[type="checkbox"] {
    transform: scale(1.5);
  }
}
