.card-row {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 1rem;

  &.card-row-narrow {
    height: 10rem;
  }
}

.card {
  background-color: white;
  box-sizing: border-box;
  padding: 1rem;
  //height: 85%;
  //width: 20%;
  flex: 1 1;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin: 0.5rem;
  overflow: auto;

  & h3 {
    font-size: 1.9rem;
    margin-bottom: 0.5rem;
  }

  & h2 {
    font-size: 2.8rem;
  }

  &.card-70 {
    width: 70%;
  }

  &.card.card-80 {
    width: 90%;
    height: 20%;
  }

  // &.card.card-15 {
  //   width: 15%;
  // }

  &.card.card-12 {
    width: 12%;

    & h2 {
      font-size: 2.5rem;
    }
  }

  &.card.card-short {
    height: 100px;
  }

  &.card-45 {
    width: 45%;
    height: 20%;
    // height: 75px;

    &.slider-card {
      & h2 {
        font-size: 2rem;
      }

      & h3 {
        font-size: 1.5rem;
      }
    }

    & h4 {
      font-size: 1.3rem;
    }
  }
  &.card-99 {
    max-width: 17vh;
    max-height: 16vh;
    //border-radius: 50px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    overflow: hidden;

    & h2 {
      font-size: 1.5rem;
    }
    & h3 {
      font-size: 2rem;
    }
  }
}
