.bridge-view {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.bridge-chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 50rem;
  margin-top: 1rem;
}

.bridge-line {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.bridge-pie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;

  & h1 {
    font-size: 3rem;
    margin: 1.5rem 0;
  }
}
