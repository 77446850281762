.chart-row {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 2rem;
  justify-content: center;
  align-items: center;
}

.chart {
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
  min-height: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.chart-left {
  flex: 0 0 60%;
  margin-right: 2rem;
}

.chart-right {
  flex: 1;
  margin-right: 4rem;
}

.chart-size {
  display: flex;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  margin: auto;
}

.chart-size-global {
  display: flex;
  font-size: 1.2rem;
  width: 45%;
  height: 50%;
}

.chart-size-bridge {
  display: flex;
  font-size: 1.2rem;
  width: 90%;
  height: 90%;
  margin: auto;
}

.pie-size {
  display: flex;
  height: 100%;
  width: 100%;
}

.pie-size-param {
  display: flex;
  height: 65%;
  width: 65%;
}
.pie-size-consensus {
  display: flex;
  height: 35%;
  width: 38%;
  margin-left: 2rem;
}

.pie-size-bridge {
  display: flex;
  height: 80%;
  width: 80%;
}

// .chart-row {
//   display: flex;
//   flex-direction: row;
//   box-sizing: border-box;
//   width: 100%;
//   height: 550px;
//   margin-top: 15px;
//   justify-content: space-evenly;
//   align-items: center;

//   & .chart {
//     background-color: white;
//     box-sizing: border-box;
//     border-radius: 10px;
//     box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
//     padding: 10px;
//     height: 90%;

//     &.chart-left {
//       width: 55%;
//     }

//     &.chart-right {
//       width: 35%;
//     }
//   }
// }

// .chart-left {
//   width: 55%;
//   height: 90%;
//   box-sizing: border-box;
// }

// .chart-right {
//   display: flex;
//   align-content: center;
//   justify-content: center;
//   width: 35%;
//   height: 90%;
//   box-sizing: border-box;
// }
