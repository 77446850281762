.global-param-view {
  display: flex;
  flex-direction: column;
  width: 98%;
  align-items: center;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  width: 95%;
}

.data-list {
  width: 45%;
  padding-left: 3rem;
  color: rgb(58, 150, 211);
  margin: auto 0;

  & h2 {
    font-size: 2.2rem;
    padding-top: 3rem;
    padding-left: 1rem;
  }
}

.pie-chart {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 55%;

  & h1 {
    font-size: 3rem;
  }
}
