.global-consensus-view {
  display: flex;
  flex-direction: column;
  width: 98%;

  &.chart-row {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 1rem;
    justify-content: center;
    align-items: center;
  }
}
