@import "../../sassStyles/variables";
.syncList-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  width: 97%;
}

h2 {
  font-size: 2rem;
}

.sync-button-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 5rem;
  margin-bottom: 1rem;

  & button {
    background-color: transparent;
    color: $primaryColor;
    height: 4rem;
    width: 8rem;
    margin: 1rem;

    box-shadow: 1px 1px 5px black;
    border: none;
    border-radius: 1rem;
  }
}

.syncList-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  max-height: 70vh;
  padding-top: 1rem;
}

.syncList-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  & h2 {
    font-size: 1.7rem;
  }

  & .dist-banner {
    box-sizing: border-box;
    padding: 1rem;
    height: 25%;
    width: 80%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 1rem;

    & p {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: left;
    }

    & .p-center {
      padding-top: 1rem;
      text-align: center;
      font-weight: 800;
    }

    & .peer-button-row {
      display: flex;
    }
  }
}

.syncList-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;

  & img {
    width: 55rem;
    margin: 2rem;
  }

  & h2 {
    font-size: 3rem;
  }

  & .dist-banner {
    background-color: white;
    box-sizing: border-box;
    padding: 1.5rem;
    height: 25%;
    width: 60%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 10rem;

    & p {
      font-size: 2rem;
      font-weight: 600;
      text-align: left;
    }

    & .p-center {
      padding-top: 1rem;
      text-align: center;
      font-weight: 800;
    }
  }
}

.sync-banner {
  background-color: white;
  padding: 1rem;
  height: 15%;
  width: 70%;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-top: 1rem;

  & h2 {
    font-size: 1.7rem;
  }

  & h3 {
    font-size: 1.8rem;
  }

  & span {
    font-size: 1.3rem;
  }
}

.buttons-row {
  display: flex;
  width: 50%;
  justify-content: center;

  & button {
    height: 5rem;
    width: 5rem;
    // padding: 1rem;
    // margin-top: -0.5rem;
    border-radius: 100px;
    z-index: 999;
  }
}

.charts-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20%;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 8rem;
  padding-top: 1rem;

  .chart-holder {
    display: flex;
    height: 80%;
    width: 33%;
  }
}

.sync-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.selected {
  background-color: rgba(0, 255, 0, 0.5);
}

// .sync-button-row {
// }

// .root{
//     width:100%;
//     max-height:100%;
//     background-color: white;

//     & .cards-column{
//         display:inline-block;
//         // background-color: red;

//     }

//     & .charts-column-top{
//         display:inline-block;
//         flex-direction: column;
//         // background-color: blue;
//         max-width: 30%;
//         max-height: 100%;
//         vertical-align: top;

//         & .charts-column-bottom{
//             display:inline-block;
//             // flex-direction: top;
//             // flex-direction: column;
//             // background-color: yellow;
//             // max-width: 30%;
//             // max-height: 40%;

//             // vertical-align: bottom;
//         }
//     }

//     & .future-information{
//         display:inline-block;
//         // background-color: orange;
//         vertical-align: top;
//     }

//     & .block-content{
//         display:inline-block;
//         vertical-align: bottom;
//         width:30%;
//         height:100%;
//         // flex-direction: column;
//         // background-color: green;

//     }

//     & table {
//         position: relative;
//         font-size: 1.5rem;
//         border: 1px solid black;
//         display: block;
//         height: 20rem;
//         overflow-y: auto;
//         margin-top: 3rem;

//         & tr{
//             text-align: center;
//         }
//         & th {
//           width: 10rem;
//           position: sticky;
//           text-align: center;
//         }

//         & td {
//           text-align: center;

//           &.time-stamp {
//             font-size: 1.2rem;
//             width: 50%;
//           }
//         }
//       }

// }
