@import "../../sassStyles/variables";
.syncList-view {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

h2 {
  font-size: 2rem;
}

.sync-button-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 5rem;
  margin-bottom: 1rem;

  & button {
    background-color: transparent;
    color: $primaryColor;
    height: 4rem;
    width: 8rem;
    margin: 1rem;

    box-shadow: 1px 1px 5px black;
    border: none;
    border-radius: 1rem;
  }
}

.syncList-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // min-height: 600px;
  padding-top: 1rem;
}

.syncList-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;

  & h2 {
    font-size: 3rem;
  }

  & .dist-banner {
    background-color: white;
    box-sizing: border-box;
    padding: 1.5rem;
    height: 25%;
    width: 80%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 1rem;

    & p {
      font-size: 2rem;
      font-weight: 600;
      text-align: left;
    }

    & .p-center {
      padding-top: 1rem;
      text-align: center;
      font-weight: 800;
    }

    & .peer-button-row {
      display: flex;
    }
  }
}

.syncList-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;

  & img {
    width: 50rem;
    margin: 2rem;
  }

  & h2 {
    font-size: 2rem;
  }

  & .dist-banner {
    background-color: white;
    box-sizing: border-box;
    padding: 1.5rem;
    height: 22%;
    width: 60%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 2rem;

    & p {
      font-size: 1.8rem;
      font-weight: 600;
      text-align: left;
    }

    & .p-center {
      padding-top: 1rem;
      text-align: center;
      font-weight: 800;
    }
  }
}

.sync-banner {
  background-color: white;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 15%;
  width: 70%;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-top: 2rem;

  & h2 {
    font-size: 2rem;
  }

  & h3 {
    font-size: 2rem;
  }

  & span {
    font-size: 1.3rem;
  }
}

.buttons-row {
  display: flex;
  width: 50%;
  justify-content: left;

  & button {
    height: 5rem;
    min-width: 5rem;
    padding: 1rem;
    margin-top: -0.5rem;
    border-radius: 100px;
    z-index: 999;
  }
}

.charts-row {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40%;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 1rem;

  .chart-holder {
    display: flex;
    height: 80%;
    width: 33%;
  }
}

.sync-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.selected {
  background-color: rgba(0, 255, 0, 0.5);
}

.sync-button-row {
}
