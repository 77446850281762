@import "./variables";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; // 10px
  text-decoration: none;
  font-family: "Manrope", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  background-color: $primaryColor;
  overflow-x: hidden;
}
